interface Conversion {
    dolarPrice: number;
    dolarPriceCurrency: string;
}

export interface BankAccountItem {
    name?: string;
    href?: string;
    dni?: string;
    bank?: string;
    accountType?: string;
    accountNumber?: string;
    ticketPrice?: string;
    ticketPriceAmount: number;
    ticketCurrency: string;
    conversion?: Conversion;
}

interface BankAccountConfig {
    accountId: string;
    bankAccounts: BankAccountItem[]
}

export const BANK_ACCOUNTS: BankAccountConfig[] = [
    {
        accountId: "oscar-alarcon",
        bankAccounts: [
            {
                name: "Oscar Alarcon",
                dni: "Rut: 27.591.242-5",
                bank: "Banco Estado 🇨🇱",
                accountType: "Cuenta Corriente",
                accountNumber: "00100416484",
                ticketPrice: "600 pesos",
                ticketPriceAmount: 600,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP"
            },
            {
                name: "Oscar Donato Alarcon",
                dni: "Rut: 27.591.242-5",
                bank: "Mercado Pago 📲",
                accountType: "Cuenta Vista",
                accountNumber: "1030647762",
                ticketPrice: "600 pesos",
                ticketPriceAmount: 600,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP"
            },
            {
                bank: "Binance 🇻🇪 🇺🇸 ",   
                name: "Oscar Alarcon",
                accountNumber: "941714665",
                ticketPrice: "$0.6 dólar",
                ticketPriceAmount: 0.6,
                ticketCurrency: "USD"
            },
            {
                name: "Michael Dominguez",
                bank: "Zelle 🇻🇪 🇺🇸",
                accountNumber: "3214404791",
                dni: "piruetasmk@gmail.com",
                ticketPrice: "$0.6 dólar",
                ticketPriceAmount: 0.6,
                ticketCurrency: "USD"
            },
            {
                bank: "Perú 🇵🇪",
                dni: "Plin: 928821360",
                ticketPrice: "$2 soles",
                ticketPriceAmount: 2,
                ticketCurrency: "SOL"
            },
            {
                bank: "Banco BBVA - España 🇪🇸",
                name: "Emmanuel Isaac Sanchez Roa",
                dni: "ES57 0182 4062 9102 0164 6755",
                ticketPrice: "$0.5 euro",
                ticketPriceAmount: 0.5,
                ticketCurrency: "EUR"
            },
            {
                name: "Jerson Andrés Alzate Cifuentes",
                dni: "Cédula: 1.0007.877.596",
                bank: "Bancolombia 🇨🇴",
                accountType: "Cuenta de ahorro",
                accountNumber: "912-268373-08",
                ticketPrice: "2000 pesos",
                ticketPriceAmount: 2000,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "COP"
            },
            {
                name: "Miguelsi Torrealba",
                dni: "Cédula: V-25.896.375",
                bank: "Banesco 🇻🇪 - 04128229757",
                accountNumber: "01340946330001536535",
                accountType: "Corriente",
                ticketPrice: "35Bs",
                ticketPriceAmount: 35,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
                conversion: {
                    dolarPrice: 47.55,
                    dolarPriceCurrency: "BS",
                }
            },
        ]
    },
    {
        accountId: "goyo2time",
        bankAccounts: [
            {
                name: "Jose Luque",
                dni: "Rut: 26.525.588-4",
                bank: "Banco de Chile 🇨🇱",
                accountType: "Cuenta Vista",
                accountNumber: "00-015-57222-15",
                ticketPrice: "2000 pesos",
                ticketPriceAmount: 2000,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP"
            },
            {
                name: "Yence Macero",
                dni: "Cédula: V-6.825.769",
                bank: "Banesco - Pago móvil 🇻🇪",
                accountNumber: "0134 0215 9421 5105 4891",
                accountType: "Corriente",
                ticketPrice: "80Bs",
                ticketPriceAmount: 80,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
                conversion: {
                    dolarPrice: 47.55,
                    dolarPriceCurrency: "BS",
                }
            },
            {
                name: "Jose Luque",
                dni: "RUT: 26.525.588-4",
                bank: "Banco Estado🇨🇱",
                accountNumber: "26525588",
                accountType: "Cuenta RUT",
                ticketPrice: "2000 CLP",
                ticketPriceAmount: 2000,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP",
            },
            {
                name: "Jose Luque",
                dni: "RUT: 26.525.588-4",
                bank: "Banco BCI🇨🇱",
                accountNumber: "777926525588",
                accountType: "Cuenta Corriente",
                ticketPrice: "2000 CLP",
                ticketPriceAmount: 2000,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP",
            },
            {
                name: "Michael Dominguez",
                bank: "Zelle 🇻🇪 🇺🇸",
                accountNumber: "3214404791",
                dni: "piruetasmk@gmail.com",
                ticketPrice: "$2 dólar",
                ticketPriceAmount: 2,
                ticketCurrency: "USD"
            },
        ]
    },
    {
        accountId: "maikel-dominguez",
        bankAccounts: [
            {
                name: "Oscar Alarcon",
                dni: "Rut: 27.591.242-5",
                bank: "Mercado Pago 🇨🇱",
                accountType: "Cuenta Vista",
                accountNumber: "1030647762",
                ticketPrice: "500 pesos",
                ticketPriceAmount: 500,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP"
            },
            {
                name: "Michael Dominguez",
                bank: "Zelle 🇻🇪 🇺🇸",
                accountNumber: "3214404791",
                dni: "piruetasmk@gmail.com",
                ticketPrice: "$0.5 dólar",
                ticketPriceAmount: 0.5,
                ticketCurrency: "USD"
            },
            {
                dni: "Cédula: V-24.179.155",
                bank: "Mercantil 0105 - Pago móvil 🇻🇪",
                accountNumber: "04241531625",
                accountType: "Corriente",
                ticketPrice: "21Bs",
                ticketPriceAmount: 21,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
                conversion: {
                    dolarPrice: 47.55, 
                    dolarPriceCurrency: "BS",
                }
            },
            {
                bank: "Paypal",
                accountNumber: "piruetasmk@gmail.com",
                dni: "piruetasmk@gmail.com",
                ticketPrice: "$0.5 dólar",
                ticketPriceAmount: 0.5,
                ticketCurrency: "USD"
            },
            {
                name: "Franklin Mera Rios",
                dni: "Cédula: 0962434452",
                bank: "Ecuador 🇪🇨",
                accountType: "Cuenta Corriente",
                accountNumber: "28255866",
                ticketPrice: "0.5 USD",
                ticketPriceAmount: 0.5,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "USD"
            },
            
        ]
    },
    {
        accountId: 'rifa-sport',
        bankAccounts: [
            {
                dni: "Cédula: V-26.901.703",
                bank: "Banco Venezuela - Pago móvil 🇻🇪",
                accountNumber: "04241982696",
                ticketPrice: "3 dólar ó 121,77Bs",
                ticketPriceAmount: 121.77,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
            },
        ]
    },
    {
        accountId: 'danielcontrerasrifas',
        bankAccounts: [
            {
                name: "Juan Carlos Perez Rodriguez",
                dni: "Rut: 26.669.894-1",
                bank: "Banco Falabella 🇨🇱",
                accountType: "Cuenta Corriente",
                accountNumber: "1-253-002108-2",
                ticketPrice: "500 pesos",
                ticketPriceAmount: 500,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP"
            },
            {
                bank: "Zelle 🇻🇪 🇺🇸",
                name: "Fausto Contreras",
                dni: "danielcontrerasrifas@gmail.com",
                ticketPrice: "$0.5 dólar",
                ticketPriceAmount: 0.5,
                ticketCurrency: "USD"
            },
            {
                dni: "Cédula: V-26.807.287",
                name: "Martha Cecilia Perez Rodriguez",
                bank: "Banco Venezuela - Pago móvil 🇻🇪",
                accountNumber: "04168782745",
                ticketPrice: "0.5 dólar ó 23.8Bs",
                ticketPriceAmount: 23.8,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
                conversion: {
                    dolarPrice: 47.55,
                    dolarPriceCurrency: "BS",
                }
            },
            {
                bank: "Paypal",
                accountNumber: "danielcontrerasrifas@gmail.com",
                dni: "danielcontrerasrifas@gmail.com",
                ticketPrice: "$0.5 dólar",
                ticketPriceAmount: 0.5,
                ticketCurrency: "USD"
            },
        ]
    },
    {
        accountId: "galenstar",
        bankAccounts: [
            {
                dni: "Cédula: V-28.416.990",
                bank: "Banco Venezuela - Pago móvil 🇻🇪",
                accountNumber: "04127746302",
                ticketPrice: "2 dólar ó 90.18Bs",
                ticketPriceAmount: 90.18,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
                conversion: {
                    dolarPrice: 47.55,
                    dolarPriceCurrency: "BS",
                }
            },
            {
                bank: "Binance 🇻🇪 🇺🇸",
                accountNumber: "837671980",
                ticketPrice: "$2 dólares",
                ticketPriceAmount: 2,
                ticketCurrency: "USD"
            },
            {
                bank: "Zelle 🇻🇪 🇺🇸",
                dni: "venezuela2022victoria@gmail.com",
                ticketPrice: "$2 dólares",
                ticketPriceAmount: 2,
                ticketCurrency: "USD"
            }
        ]
    },
    {
        accountId: "maikel-jaimez",
        bankAccounts: [
            {
                dni: "Cédula: V-21.376.723",
                bank: "Banco Mercantil - Pago móvil 🇻🇪",
                accountNumber: "04242228877",
                ticketPrice: "1 dólar ó 40Bs",
                ticketPriceAmount: 40,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
                conversion: {
                    dolarPrice: 47.55,
                    dolarPriceCurrency: "BS",
                }
            },
            {
                dni: "RUT: 28.113.514-7",
                name: "Jose Martinez",
                accountType: "Cuenta Corriente",
                bank: "BCI/MACH 🇨🇱",
                accountNumber: "777928113514",
                ticketPrice: "1000 pesos",
                ticketPriceAmount: 1000,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP",
            },
            {
                name: "Maikel Jaimez",
                bank: "Zelle 🇺🇸",
                dni: "maikel_jaimez160@hotmail.com",
                ticketPrice: "$1 dólar",
                ticketPriceAmount: 1,
                ticketCurrency: "USD"
            },
            {
                name: "Maikel Jaimez",
                bank: "Yape Perú 🇵🇪",
                dni: "+51 921 565 925",
                ticketPrice: "4 soles",
                ticketPriceAmount: 4,
                ticketCurrency: "SOL"
            }
        ]
    },
    {
        accountId: "jjmc",
        bankAccounts: [
            {
                name: "Johandry Cantillo",
                dni: "Rut: 26498924-6",
                bank: "Banco Estado 🇨🇱",
                accountType: "Cuenta RUT",
                accountNumber: "26498924",
                ticketPrice: "3000 pesos",
                ticketPriceAmount: 3000,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP"
            },      
            {
                bank: "Zelle 🇻🇪 🇺🇸",
                dni: "johandrycan@gmail.com",
                ticketPrice: "$3 dólares",
                ticketPriceAmount: 3,
                ticketCurrency: "USD"
            }
        ]
    },
    {
        accountId: "blessed-disc-bar-zz",
        bankAccounts: [
            {
                name: "Pablo Emiliano Díaz Gutiérrez",
                bank: "Bancolombia 🇨🇴",
                dni: "Cédula: 1046743290",
                accountType: "Cuenta de ahorro",
                accountNumber: "31156081330",
                ticketPrice: "2000 pesos",
                ticketPriceAmount: 2000,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "COP"
            },
            {
                name: "Erlin Orozco",
                bank: "Zelle 🇻🇪 🇺🇸",
                accountNumber: "+1 (929) 317-7257",
                ticketPrice: "$0.5 dólar",
                ticketPriceAmount: 0.5,
                ticketCurrency: "USD"
            },
            {
                name: "Keiver José Pacheco Pernalete",
                dni: "Cédula: V303334670",
                bank: "Banco de Venezuela 🇻🇪",
                accountType: "Cuenta de ahorro",
                accountNumber: "01020327910000432380",
                ticketPrice: "20 bs",
                ticketPriceAmount: 20,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS"
            },
            {
                name: "0412-8670001",
                dni: "Cédula: V30334670",
                bank: "Banco de Venezuela - Pago móvil 🇻🇪",
                accountType: "Corriente",
                accountNumber: "0412-8670001",
                ticketPrice: "20Bs",
                ticketPriceAmount: 20,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS"
            },
            {
                bank: "Binance",
                accountNumber: "837671980",
                dni: "leonel.roca1@icloud.com",
                ticketPrice: "$0.5 dólar",
                ticketPriceAmount: 0.5,
                ticketCurrency: "USD"
            },
        ]
    },
]
